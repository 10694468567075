
import { defineComponent } from 'vue';
import SearchDropdown from '@/components/search/SearchDropdown.vue';
import BaseInput from '@/components/ui/BaseInput.vue';
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import BaseDatePicker from '@/components/ui/BaseDatePicker.vue';

export default defineComponent({
  name: 'ApproveChooseRequestsModal',
  components: {
    SearchDropdown,
    BaseInput,
    ModalWrapper,
    BaseDatePicker,
  },
  data: () => ({
    requestData: {
      dateRange: null,
      number: null,
    } as any
  }),
  computed: {
    isDisabled(): boolean {
      return Object
        .values(this.requestData)
        .some((value: any) => !value)
    }
  },
  methods: {
    closeModal() {
      this.setDefault()
      this.$emit('closeModal')
    },
    changeDateRange(dateRange: any) {
      this.requestData.dateRange = dateRange
    },
    setDefault() {
      Object.keys(this.requestData).map((key: string) => {
        this.requestData[key] = null
      })
    },
    async create() {
      this.$emit('create', this.requestData)
      this.closeModal()
    },
  }
})


import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import { defineComponent } from 'vue';
import BaseTabs from '@/components/ui/BaseTabs.vue';
import AddRequestForm from '@/components/certificates/upload-and-add-request/AddRequestForm.vue';
import UploadRequestForm
  from '@/components/certificates/upload-and-add-request/UploadRequestForm.vue';

export default defineComponent({
  name: 'UploadAndAddRequestModal',
  components: {
    UploadRequestForm,
    AddRequestForm,
    ModalWrapper,
    BaseTabs,
  },
  props: {
    getRequests: {
      type: Function,
      default: () => {}
    },
    certificateType: String,
  }
  // data: () => ({
  //   tabs: [
  //     { name: 'По одному артикулу', query: 'some-articles'},
  //     { name: 'Массовое добавление', query: 'from-file'},
  //   ]
  // }),
})

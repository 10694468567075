
import { defineComponent } from 'vue';
import SearchDropdown from '@/components/search/SearchDropdown.vue';
import BaseInput from '@/components/ui/BaseInput.vue';

export default defineComponent({
  name: 'AddRequestForm',
  components: {
    SearchDropdown,
    BaseInput
  },
  data: () => ({
    requestData: {
      article: null,
      number: null,
      productGroup: null,
      productSubGroup: null,
      brand: null
    } as any
  }),
  computed: {
    isDisabled(): boolean {
      return Object
        .values(this.requestData)
        .some((value: any) => !value)
    }
  },
  methods: {
    setDefault() {
      Object.keys(this.requestData).map((key: string) => {
        this.requestData[key] = null
      })
    },
    async saveCertificate() {
      await this.$store.dispatch('addRequest', [{
        article: this.requestData.article,
        numberOfRegulation: this.requestData.number,
        pgId: this.requestData.productGroup.id,
        psgId: this.requestData.productSubGroup.id,
        brandId: this.requestData.brand.brandId
      }]).then(() => this.setDefault())
    },
  }
})


import { defineComponent } from 'vue';
import BaseTable from '@/components/ui/BaseTable.vue';
import { LayoutRequests } from '@/models/CertificateModel';
import UploadAndAddRequestModal from '@/components/modals/UploadAndAddRequestModal.vue';
import BasePagination from '@/components/ui/BasePagination.vue';
import SelectElementWithContentModal from '@/components/modals/SelectElementWithContentModal.vue';
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';
import ApproveChooseRequestsModal from '@/components/modals/ApproveChooseRequestsModal.vue';
import CertificateFiltersModal
  from '@/components/modals/CertificateFiltersModal.vue';
import certificates from '@/services/certificates';
import { ConvertDate } from '@/components/converters/date';
import DropdownEllipsis from '@/components/admin-panel/DropdownEllipsis.vue';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';

export default defineComponent({
  name: 'CertificateLayoutsRequests',
  components: {
    CertificateFiltersModal,
    ApproveChooseRequestsModal,
    SelectElementWithContentModal,
    UploadAndAddRequestModal,
    BaseTable,
    BasePagination,
    BaseCheckbox,
    DropdownEllipsis,
  },
  data: () => ({
    pageable: {
      pageNumber: 0,
      pageSize: 50,
      totalPages: null
    },
    selectedLayoutRequestForApprove: null as LayoutRequests | null,
  }),
  computed: {
    layoutsRequests(): Array<LayoutRequests> {
      return this.$store.getters.getLayoutsRequests
    },
    headers(): any {
      return [
        {
          title: 'Дата создания',
          content: (item: LayoutRequests) => ConvertDate(item.createdAt)
        },
        {
          title: 'Пользователь',
          content: (item: LayoutRequests) => item.user
        },
      ]
    },
    dropdownOptions() {
      return (item: LayoutRequests) => {
        return [
          {
            name: 'Скачать макет',
            method: () => this.downloadLayout(item)
          },
          {
            name: 'Удалить макет',
            method: () => this.deleteLayout(item)
          },
          {
            name: "Сменить статус на 'Сертифицированный'",
            method: () => this.openModalChooseApproveDate(item)
          },
        ]
      }
    }
  },
  methods: {
    setDefault() {
      this.selectedLayoutRequestForApprove = null
    },
    openModalChooseApproveDate(item: LayoutRequests) {
      this.selectedLayoutRequestForApprove = item
      const element = document.getElementById('modal-approve-choose-requests')!;
      element.style.display = 'flex'
    },
    closeModalChooseApproveDate() {
      this.setDefault()
      const element = document.getElementById('modal-approve-choose-requests')!;
      element.style.display = 'none'
    },
    async approveLayout(requestData: any) {
      if (this.selectedLayoutRequestForApprove) {
        await this.$store.dispatch('changeRequestsStatusToApprove', {
          requestId: this.selectedLayoutRequestForApprove.id,
          numberOfCertification: requestData.number,
          createdAt: new Date(requestData.dateRange.start).toISOString(),
          expiredAt: new Date(requestData.dateRange.end).toISOString(),
        }).then(() => {
          this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
          this.getLayoutsRequests({ pageNumber: 0 })
        }).catch(() => {
          this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR')
        }).finally(() => {
          this.setDefault()
        })
      }
    },
    deleteLayout(item: LayoutRequests) {
      this.$store.dispatch('deleteLayoutRequests', item.id)
    },
    downloadLayout(item: LayoutRequests) {
      const href = certificates.downloadLayoutRequests(item.id)
      const link = document.createElement('a');
      link.setAttribute('href', href);
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
    },
    async getLayoutsRequests(nextPageable: { pageNumber: number }) {
      const { pageable: { pageNumber }, totalPages } = await this.$store.dispatch('fetchLayoutsRequests', {
        ...nextPageable,
        pageSize: this.pageable.pageSize
      })

      this.pageable = {
        ...this.pageable,
        pageNumber,
        totalPages
      }

      // this.setDefaultSettingsDocument()
    }
  },
  async mounted() {
    await this.getLayoutsRequests(this.pageable)
  }
})
